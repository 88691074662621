import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons'
import AccountContent from '../components/accountContent';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import AppConfig from '../appconfig';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  reset
} from "../state/actions";



class ForgotPassword extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			resetPassFeedback: '',
			email: '',
			successFeedback: ''
		};

		this.handleSendResetPassToken = this.handleSendResetPassToken.bind(this);
		this.sendResetPassToken = this.sendResetPassToken.bind(this);
		this.handleemailChange = this.handleemailChange.bind(this);
	}


	handleemailChange(event) {
		this.setState({
			email: event.target.value
		});
	}

	handleSendResetPassToken(event) {
		if (this.state.isLoading) {
			return;
		}

		this.setState({
			isLoading: true
		});
		
		event.preventDefault();
		event.stopPropagation();

		this.sendResetPassToken();
	}

	sendResetPassToken() {
		let url = AppConfig.API_URL + 'sendResetPassToken';

		url += ('/' + this.state.email);

	 	fetch(url, {
	 		credentials: 'include'
	 	})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					this.setState({
						successFeedback: 'Om din e-postadress hittades har ett meddelande skickats till dig med instruktioner för att återställa ditt lösenord!',
						saveSettingsFeedback: '',
						isLoading: false
					});
				} else {
					let feedback = 'Kunde inte skicka. Försök igen senare.';
					this.setState({
						resetPassFeedback: feedback,
						isLoading: false
					});
				}
			},
			error => {
				this.setState({
					resetPassFeedback: 'Kunde inte skicka. Försök igen senare.',
					isLoading: false
				});
			}
		);

		return false; 		
	}

 	render() {

        if (this.props.loggedIn) {
        	return (
				<div className="chatPageWrapper page">
					<div className="errorText">Du är redan inloggad!</div>
				</div>
			);

        } else if (this.state.successFeedback != undefined && this.state.successFeedback != ''){
        	return (
				<div className="chatPageWrapper page">
					<div className="errorText">{this.state.successFeedback}</div>
				</div>
			);
        } else {
        	return(
				<div className="chatPageWrapper page">
					<div className="col-xl-8 order-xl-1 mb-5 resetWrapper">
						<div className="card settings cardShadow">
							<div className="card-header bg-white border-0">
								<div className="row">
									<div className="col-8 resetTitle">
										<h6 className="mb-0">Återställ ditt lösenord</h6>
									</div>	
								</div>
							</div>
							<div className="card-body">
				             
					            <Form onSubmit={this.handleSendResetPassToken}>
									<Form.Group>
										<Form.Label>E-postadress</Form.Label>
										<Form.Control type="text" name="title" value={this.state.email} placeholder="Din e-postadress" onChange={this.handleemailChange} />
									</Form.Group>

									<p className="feedback">{this.state.resetPassFeedback}</p>
									<Button type="submit" disabled={this.state.isLoading}>{this.state.isLoading ? 'Skickar..' : 'Skicka'}</Button>
								</Form>
				            </div>
						</div>
					</div>
				</div>
			);
        }
		
	}
}

function mapStateToProps(state) {
	const { loggedIn } = state
	return { loggedIn: loggedIn }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		reset: reset
	},
	dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ForgotPassword);